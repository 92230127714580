/* src/App.css */

/* Reset basic styles */
body, html, #root, .App {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #333; /* Dark gray background */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

/* Logo styling */
.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: Consolas, monospace;
  font-size: 2.5em;
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
}

/* About link styling, positioned in bottom-left */
.about-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  font-weight: bold;
  font-family: Consolas, monospace;
}

.about-link:hover {
  opacity: 0.8;
}

/* Main content styling */
.content {
  font-family: Consolas, monaco, monospace;
  color: #fff;
}

.content h1 {
  font-size: 2.5em;
  margin: 0;
  font-family: Consolas, monaco, monospace;
}

/* Style for the main title */
h1 {
  font-size: 2em;
  margin: 0;
  font-family: Consolas, monospace;
}

/* Blinking underscore effect */
.blinking-underscore {
  animation: blink 1s steps(2, start) infinite;
  font-family: Consolas, monospace;
}

/* Blinking animation */
@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

/* About message styling */
.about-message {
  font-size: 1.2em;
  max-width: 600px;
  padding: 20px;
  margin: 0 auto;
  line-height: 1.5;
  font-family: Consolas, monaco, monospace;
}

/* Subtitle styling */
p {
  font-size: 1.2em;
  opacity: 0.8;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .logo {
    top: 10px;
    left: 10px;
    width: 80px;
  }

  .about-link {
    bottom: 10px;
    left: 10px;
    font-size: 1.2em;
  }
}

.blinking-emoji {
  animation: blinker 3s linear infinite;
  display: inline-block;
}

@keyframes blinker {
  0%, 50% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}
